import { useContext } from 'react';
import { Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import UserContext from '../../Context/user-context';
import styles from './Upskilling.module.less';

const Upskilling = (props) => {
  const userCtx = useContext(UserContext);
  const { loading, data } = userCtx;

  let upskilling;
  if (data) {
    upskilling = data.program[0].upskilling;
  }

  return (
    <div className='container'>
      {loading ? null : data ? (
        <>
    {data.program[0].name !== "Global Tech Initiative Scholar (4 months)" && (
          <div className='box'>
          
          <h3>
    Once onboarded, we would advise you to kickoff your first 2 weeks at 
    {data.program[0].name === "Impact Lab Young Leaders" ? " Impact Lab, YLP " : 
    (data.program[0].name === "Global Tech Initiative Scholar (6 months)" || data.program[0].name === "GTI Feynman Fellow" ? " Global Tech Initiative " : " GGI ")} 
    in following {data.program[0].name === "Global Tech Initiative Scholar (6 months)" ? "4" : "3"} step order-
</h3>

           
            <div dangerouslySetInnerHTML={{
              __html: upskilling.onboardingSteps
            }}></div>
          </div>
            )}

          {upskilling.masterclass && (
            <div className='box'>
              <h3 className='box-title'>Masterclass</h3>
              {upskilling.masterclass.map((item, index) => (
                <p key={index} dangerouslySetInnerHTML={{ __html: item }}></p>
              ))}
            </div>
          )}

{upskilling.assignment && (
  <div className='box'>
    <h3 className='box-title'>Assignments</h3>
    {upskilling.assignment.map((item, index) => (
      <p key={index} dangerouslySetInnerHTML={{ __html: item }}></p>
    ))}
  </div>
)}

          {upskilling.businessreview && (
            <div className='box'>
              <h3 className='box-title'>{data.program[0].name === "Impact Lab Young Leaders" ? "Impact Lab, YLP" : "GGI"} Business Review</h3>
              {upskilling.businessreview.map((item, index) => (
                <p key={index} dangerouslySetInnerHTML={{ __html: item }}></p>
              ))}
            </div>
          )}
          {upskilling.schrodinger && (
            <div className={`${styles.item} box`}>
              <h3 className='box-title'>Schrodinger</h3>
              {upskilling.schrodinger.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
              <img
                src='/schrodinger.png'
                alt='Schrodinger'
                className={styles.item__img}
              />

              <div className={styles.item__btn}>
                <Button
                  type='primary'
                  icon={<LogoutOutlined rotate='-45' />}
                  href='http://www.schrodingerbyggi.org'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Schrodinger
                </Button>
              </div>
            </div>
          )}
          {upskilling.trinity && (
            <div className={`${styles.item} box`}>
              <h3 className='box-title'>Trinity</h3>
              {upskilling.trinity.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
              <img
                src='/trinity.png'
                alt='Trinity'
                className={styles.item__img}
              />
              <div className={styles.item__btn}>
                <Button
                  type='primary'
                  icon={<LogoutOutlined rotate='-45' />}
                  href='https://www.thetrinitybyggi.org'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Trinity
                </Button>
              </div>
            </div>
          )}
          {upskilling.preread && (
            <div className={`${styles.item} box`}>
              <h3 className='box-title'>Masterclass Pre Read</h3>
              <p dangerouslySetInnerHTML={{__html:upskilling.preread}}></p>

            </div>
          )}
          {upskilling.startupweekend && (
            <div className='box'>
              <h3 className='box-title'>
  {data.program[0].name === "Global Tech Initiative Scholar (6 months)" || 
   data.program[0].name === "Global Tech Initiative Scholar (4 months)" || 
   data.program[0].name === "GTI Feynman Fellow" ? "Global Tech Initiative" : "GGI"} Startup Weekend
</h3>

              {upskilling.startupweekend.map((item, index) => (
                <p key={index} dangerouslySetInnerHTML={{ __html: item }}></p>
              ))}
            </div>
          )}
           {upskilling.gtitrinity && (
            <div className={`${styles.item} box`}>
              <h3 className='box-title'><a target='_blank' rel='noopener noreferrer' href='https://thetrinitybyggi.org/'>Trinity</a> of GTI</h3>
              <p dangerouslySetInnerHTML={{__html:upskilling.gtitrinity}}></p>

            </div>
          )}
          {upskilling.gtischrodinger && (
            <div className={`${styles.item} box`}>
              <h3 className='box-title'><a target='_blank' rel='noopener noreferrer' href='https://www.schrodingerbyggi.org/'>Schrodinger</a> of GTI</h3>
              <p dangerouslySetInnerHTML={{__html:upskilling.gtischrodinger}}></p>

            </div>
          )}
          {upskilling.resume && (
            <div className={`${styles.item} box`}>
              <h3 >We have carefully curated a list of external sources for your <b>resume enhancement,</b> we would encourage you to go through the same-</h3>
              <p dangerouslySetInnerHTML={{__html:upskilling.resume}}></p>

            </div>
          )}
           {upskilling.aipmpaper && (
            <div className={`${styles.item} box`}>
              <h3 className='box-title'>AI/PM Research Paper- With GGI mentor after completing level 1 (4 masterclasses) at GTI</h3>
              <p dangerouslySetInnerHTML={{__html:upskilling.aipmpaper}}></p>

            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

export default Upskilling;



